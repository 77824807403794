<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-black"
  >
    <g clip-path="url(#clip0_2505_10748)">
      <path
        d="M16 8.04687C16 3.62887 12.418 0.046875 8 0.046875C3.582 0.046875 0 3.62887 0 8.04687C0 12.0402 2.92533 15.3495 6.75 15.9495V10.3595H4.71867V8.04621H6.75V6.28487C6.75 4.28021 7.94467 3.17221 9.772 3.17221C10.6467 3.17221 11.5627 3.32887 11.5627 3.32887V5.29754H10.5533C9.55933 5.29754 9.24933 5.91421 9.24933 6.54687V8.04687H11.468L11.1133 10.3602H9.24933V15.9502C13.0747 15.3495 16 12.0395 16 8.04687Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2505_10748">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
